import {
	createRouter,
	createWebHistory,
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import("../views/home"),
		meta: {
			title: '晟麦家油卡 - SanMark'
		}
	}, {
		path: '/change',
		name: 'change',
		component: () => import("../views/change"),
		meta: {
			title: '兑换家油卡'
		}
	}, {
		path: '/order',
		name: 'order',
		component: () => import("../views/order"),
		meta: {
			title: '我的订单'
		}
	}, {
		path: '/order-detail',
		name: 'orderDetail',
		component: () => import("../views/orderDetail"),
		meta: {
			title: '订单详情'
		}
	}, {
		path: '/order-progress',
		name: 'orderProgress',
		component: () => import("../views/orderProgress"),
		meta: {
			title: '订单进度'
		}
	}, {
		path: '/404',
		name: '404',
		component: () => import("../views/404"),
		meta: {
			title: '404  - ISTERO WEB SERVICE'
		}
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/404',
		hidden: true
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	window.document.title = to.meta.title
	next()
})


export default router